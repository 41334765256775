.iosButton{
    background-color: black;
    padding: 12px 30px;
    border-radius: 30px;
    width: 100px;

  
}
.bigWhiteText{
    font-family: "Roboto, sans-serif";
    font-size: large;
   font-weight: 700;
   color: white;

}
.smallWhiteText{
    font-family: "Roboto, sans-serif";
    font-size: medium;
    font-weight: 500;
    color: white;

}